




























import { defineComponent, ref } from '@vue/composition-api';

enum Filters {
  STH = 'sth',
  PICKUP = 'pickup',
}

export default defineComponent({
  name: 'VfShippingFilter',
  setup() {
    const selected = ref<Filters>(Filters.STH);

    const onSelect = (select: Filters) => {
      selected.value = select;
    };
    return {
      Filters,
      onSelect,
      selected,
    };
  },
});
